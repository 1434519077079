// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars            as fasFaBars            } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faAngleDown       as fasFaAngleDown       } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft       as fasFaAngleLeft       } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight      as fasFaAngleRight      } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAngleUp         as fasFaAngleUp         } from "@fortawesome/pro-solid-svg-icons/faAngleUp";
import { faCircleQuestion  as fasFaCircleQuestion  } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser      as fasFaCircleUser      } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faClock           as fasFaClock           } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment         as fasFaComment         } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faEnvelope        as fasFaEnvelope        } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faFolder          as fasFaFolder          } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faHeart           as fasFaHeart           } from "@fortawesome/pro-solid-svg-icons/faHeart";
import { faMagnifyingGlass as fasFaMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faTag             as fasFaTag             } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faThumbsUp        as fasFaThumbsUp        } from "@fortawesome/pro-solid-svg-icons/faThumbsUp";
import { faXmark           as fasFaXmark           } from "@fortawesome/pro-solid-svg-icons/faXmark";

import { faFacebook        as fabFaFacebook        } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram       as fabFaInstagram       } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedin        as fabFaLinkedin        } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faTwitter         as fabFaTwitter         } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faXTwitter        as fabFaXTwitter        } from "@fortawesome/free-brands-svg-icons/faXTwitter";
import { faYoutube         as fabFaYoutube         } from "@fortawesome/free-brands-svg-icons/faYoutube";

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaAngleDown, fasFaAngleLeft, fasFaAngleRight, fasFaAngleUp, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaComment, fasFaEnvelope, fasFaFolder, fasFaHeart, fasFaMagnifyingGlass, fasFaTag, fasFaThumbsUp, fasFaXmark);

/**
 * Add brand icons
 */
library.add(fabFaFacebook, fabFaInstagram, fabFaLinkedin, fabFaTwitter, fabFaXTwitter, fabFaYoutube);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
